import { generateClient } from "aws-amplify/api";
import { createCount, updateCount, deleteCount } from "./graphql/mutations";
import * as queries from "./graphql/queries";

const client = generateClient();

const init = async (userId) => {
  if (userId === "") {
    return;
  }
  const variables = {
    filter: {
      account: {
        eq: userId,
      },
    },
  };
  try {
    const result = await client.graphql({
      query: queries.listCounts,
      variables: variables,
    });
    if (result.data.listCounts.items.length) {
      return result.data.listCounts.items[0];
    } else {
      await createNumberTries(userId);
      const result = await client.graphql({
        query: queries.listCounts,
        variables: variables,
      });
      return result.data.listCounts.items[0];
    }
  } catch {
    console.log("An error occured with the database");
  }
  return false;
};

export const getNumberTries = async (userId) => {
  if (userId === "") {
    return;
  }
  const item = await init(userId);
  if (!item) return 0;
  return { updatedAt: item.updatedAt, value: item.value };
};

export const setNumberTries = async (userId, value) => {
  if (userId === "") {
    return;
  }
  try {
    await init(userId).then(async (item) => {
      if (!item || item.value === value) return;
      const countId = item.id;
      countId !== "" &&
        (await client.graphql({
          query: updateCount,
          variables: {
            input: {
              id: countId,
              value: value,
            },
          },
        }));
    });
  } catch (e) {
    console.log("An error occured with the database");
  }
};

export const createNumberTries = async (userId) => {
  if (userId === "") {
    return;
  }
  await client.graphql({
    query: createCount,
    variables: {
      input: {
        account: userId,
        value: 1, //TODO: put 1 or 2 later // TODO: env variable
      },
    },
  });
};

export const deleteNumberTries = async (userId) => {
  if (userId === "") {
    return;
  }
  try {
    const item = await init(userId);
    if (!item) return;
    const countId = item.id;
    countId !== "" &&
      (await client.graphql({
        query: deleteCount,
        variables: {
          input: {
            id: countId,
          },
        },
      }));
  } catch (e) {
    console.log("An error occured with the database");
  }
};
