import AuthComponent from "../components/AuthComponent";
import Scroll from "../components/Scroll";
import Header from "../components/Header";
import HostPage from "../components/HostPage";
import GenderSelPage from "../components/GenderSelPage";
import ImagePage from "../components/ImagePage";
import ParametersPage from "../components/ParametersPage";
import ResultPage from "../components/ResultPage";
import AboutPage from "../components/AboutPage";
import Footer from "../components/Footer";
import { deleteNumberTries } from "../numberTries";

import { deleteUser } from "aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";

const Home = () => {
  let [gender, setGender] = useState("woman");
  let [isNude, setIsNude] = useState("underwear");
  let [isPhoto, setIsPhoto] = useState("photo");
  let [power, setPower] = useState("light");
  let [source, setSource] = useState("");
  let [isLoaded, setIsLoaded] = useState(false);
  let [isAuth, setIsAuth] = useState(false);
  let [userId, setUserId] = useState("");
  let [doAuth, setDoAuth] = useState(false);
  let [inImgSrc, setInImgSrc] = useState("");
  let [hasDrawn, setHasDrawn] = useState(false);
  let [isSub, setIsSub] = useState({ value: false });
  let [doSub, setDoSub] = useState(false);
  let [displayPopUp, setDisplayPopUp] = useState(true);

  useEffect(() => {
    // getting value of "seenPopUp" key from localStorage
    let returningUser = localStorage.getItem("seenPopUp");
    // if it's not there, for a new user, it will be null
    // if it's there it will be boolean true
    // setting the opposite to state, false for returning user, true for a new user
    setDisplayPopUp(!returningUser);
  }, []);

  const closePopUp = () => {
    // setting key "seenPopUp" with value true into localStorage
    localStorage.setItem("seenPopUp", true);
    // setting state to false to not display pop-up
    setDisplayPopUp(false);
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser();
      deleteNumberTries(userId);
    } catch (error) {
      console.log(error);
    }
  };

  // write some style to pass into modal
  const style = {
    fontFamily: "Montserrat",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    height: 300,
    border: "10px solid hotpink",
    boxShadow: 24,
    padding: 5,
    textAlign: "center",
    background: "#de4899",
  };

  return (
    <div className="App">
      {doAuth && (
        <div className="auth">
          <AuthComponent></AuthComponent>
          <button
            className="button_back"
            onClick={() => {
              setDoAuth(!doAuth);
            }}
          >
            Get back to website
          </button>
        </div>
      )}
      {displayPopUp && (
        <Modal
          open={true}
          // once pop-up will close "closePopUp" function will be executed
          onClose={closePopUp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* what user will see in the modal is defined below */}
            <h1>Are you 18+ ?</h1>
            <br />
            <p>
              By clicking Yes, you confirm that you are over 18 years old and
              agree to the Terms of Service and Privacy Policy.
            </p>
            <button onClick={closePopUp} style={{ width: "50px" }}>
              {" "}
              Yes{" "}
            </button>
          </Box>
        </Modal>
      )}
      {!doAuth && (
        <>
          <Scroll></Scroll>
          <Header
            onSignIn={(bool) => setDoAuth(bool)}
            isAuth={isAuth}
            onAuth={(bool, id) => {
              setIsAuth(bool);
              setUserId(id);
            }}
            isSub={isSub}
            onIsSub={(value) => setIsSub(value)}
            doSub={doSub}
            onDeleteAccount={handleDeleteUser}
          ></Header>
        </>
      )}
      <HostPage></HostPage>
      <GenderSelPage onClick={(gender) => setGender(gender)}></GenderSelPage>
      {!doAuth && (
        <ImagePage
          onLoad={(url, source) => {
            setIsLoaded(true);
            setInImgSrc(url);
            setSource(source);
          }}
          inImgSrc={inImgSrc}
          onDraw={(bool) => setHasDrawn(bool)}
        ></ImagePage>
      )}
      {isLoaded && (
        <ParametersPage
          gender={gender}
          isNude={isNude}
          isPhoto={isPhoto}
          power={power}
          onClickGender={(e) => setGender(e.currentTarget.value)}
          onClickIsNude={(e) => {
            setIsNude(e.currentTarget.value);
            // if (isSub["value"]) setIsNude(e.currentTarget.value); // TODO: for later when payment is put
            // else
            //   alert(
            //     "Please subscribe to any plan to use the 'nude' functionality"
            //   );
          }}
          onClickIsPhoto={(e) => {
            setIsPhoto(e.currentTarget.value);
          }}
          onClickPower={(e) => {
            setPower(e.currentTarget.value);
          }}
          onClickParams={() => {
            alert(
              "Please subscribe to Pro plan to access these functionalities"
            );
          }}
        ></ParametersPage>
      )}
      {isLoaded && (
        <ResultPage
          isLoaded={isLoaded}
          isAuth={isAuth}
          inImgSrc={inImgSrc}
          hasDrawn={hasDrawn}
          gender={gender}
          isNude={isNude}
          isPhoto={isPhoto}
          power={power}
          source={source}
          onNoMoreTry={() => setDoSub(true)}
        ></ResultPage>
      )}
      <AboutPage></AboutPage>
      <Footer></Footer>
    </div>
  );
};

export default Home;
