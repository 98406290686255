import React, { useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";

import AuthComponent from "../components/AuthComponent";
import { fetchUserAttributes } from "@aws-amplify/auth";
import Footer from "../components/Footer";

import PricingContainer from "../components/pricing/pricing-component-container";

const Subscriptions = () => {
  let [userEmail, setUserEmail] = useState("");
  let [event, setEvent] = useState(false);

  const listener = (data) => {
    setEvent(true);
  };
  Hub.listen("auth", listener);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        setUserEmail(userAttributes.email);
      } catch (error) {
        console.log("You need to sign in first");
        console.error(error);
      }
    };
    fetchData();
    setEvent(false);
    // const script = document.createElement("script");
    // script.src = "https://js.stripe.com/v3/pricing-table.js";
    // script.async = true;
    // document.body.appendChild(script);
    // return () => {
    //   document.body.removeChild(script);

    // };
  }, [event]);

  return (
    <>
      <div>
        <AuthComponent> </AuthComponent>
        {userEmail === "" && <label> Loading data ...</label>}
        {/* {userEmail !== "" && (
          <stripe-pricing-table
            pricing-table-id="prctbl_1Q0AP5B6xInYzrcG7XxQvKlS"
            publishable-key="pk_test_51PXmy9B6xInYzrcG6L3NCxxa3p9KwTfa4dnDaPar3DrNXwWXodS3PxLI9UhBu2IifVRD9ZW4nMyogke9mLebpUdp00oprSqvaZ"
            customer-email={userEmail}
          ></stripe-pricing-table>
        )} */}
        {userEmail !== "" && <PricingContainer />}
      </div>
      <Footer></Footer>
    </>
  );
};

export default Subscriptions;
