import React, { useState, useRef } from "react";
import Resizer from "react-image-file-resizer";
import SmallSlider from "./smallImgSlider";
import lisa from "./images/Lisa.jpg";
import locked from "./images/Locked.jpg";
import lisa_selfie from "./images/Lisa/lisa_selfie.jpg";
import lisa_gold from "./images/Lisa/lisa_gold.jpg";
import lisa_beach from "./images/Lisa/lisa_beach.jpg";
import lisa_beach2 from "./images/Lisa/lisa_beach2.jpg";
import lisa_school from "./images/Lisa/lisa_school.jpg";
import lisa_sea from "./images/Lisa/lisa_sea.jpg";
import lisa_bikini from "./images/Lisa/lisa_bikini.jpg";
import lisa_mirror from "./images/Lisa/lisa_mirror.jpg";
import lisa_defile from "./images/Lisa/lisa_defile.jpg";

const ImagePage = ({ onLoad, inImgSrc, onDraw }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [modelName, setModelName] = useState("");

  const maskRef = useRef(null);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000, // new image max width // TODO; env var ?
        1000, // new image max height
        "JPEG", // default type
        100, // new image quality
        0, // rotation degree
        (uri) => {
          resolve(uri); // resized new image uri
        },
        "file" // output type
      );
    });

  const resetCanvas = () => {
    const canvas = document.getElementById("canvas");
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
    onDraw(false);
  };

  const viewFile = async () => {
    let input = document.getElementById("fileInput");
    if (!input.files.length) return;
    let inputFile = input.files[0];
    let fileType = inputFile.type;
    let validExtensions = ["image/jpeg", "image/jpg", "image/png"];
    if (validExtensions.includes(fileType)) {
      if (inputFile.size > 50 * 50 * 1024 * 1024) {
        alert(
          "The allowed file size is 50MB. The file you are trying to upload is too large !"
        );
      } else {
        maskRef.current.scrollIntoView();
        let fileReader = new FileReader();
        fileReader.onload = () => {
          let fileURL = fileReader.result;
          setModelName("");
          setIsLoaded(true);
          onLoad(fileURL, "upload");
        };
        const resizedFile = await resizeFile(inputFile);
        fileReader.readAsDataURL(resizedFile);
      }
    } else {
      alert("This is not an Image File!");
    }
  };

  const draw = () => {
    if (!document.getElementById("canvas")) {
      return;
    }

    // Brush colour and size
    const colour = "#3d34a5";
    const strokeWidth = 50;

    // Drawing state
    let latestPoint;
    let drawing = false;

    // Set up our drawing context
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");

    // Drawing functions

    const continueStroke = (newPoint) => {
      context.beginPath();
      context.moveTo(latestPoint[0], latestPoint[1]);
      context.strokeStyle = colour;
      context.lineWidth = strokeWidth;
      context.lineCap = "round";
      context.lineJoin = "round";
      context.lineTo(newPoint[0], newPoint[1]);
      context.stroke();

      latestPoint = newPoint;
    };

    // Event helpers

    const startStroke = (point) => {
      onDraw(true);
      context.beginPath();
      drawing = true;
      latestPoint = point;
    };

    const BUTTON = 0b01;
    const mouseButtonIsDown = (buttons) => (BUTTON & buttons) === BUTTON;

    // Event handlers

    const mouseMove = (evt) => {
      if (!drawing) {
        return;
      }
      continueStroke([evt.offsetX, evt.offsetY]);
    };

    const mouseDown = (evt) => {
      if (drawing) {
        return;
      }
      evt.preventDefault();
      canvas.addEventListener("mousemove", mouseMove, false);
      startStroke([evt.offsetX, evt.offsetY]);
    };

    const mouseEnter = (evt) => {
      if (!mouseButtonIsDown(evt.buttons) || drawing) {
        return;
      }
      mouseDown(evt);
    };

    const endStroke = (evt) => {
      if (!drawing) {
        return;
      }
      drawing = false;
      evt.currentTarget.removeEventListener("mousemove", mouseMove, false);
    };

    // Register event handlers

    canvas.addEventListener("mousedown", mouseDown, false);
    canvas.addEventListener("mouseup", endStroke, false);
    canvas.addEventListener("mouseout", endStroke, false);
    canvas.addEventListener("mouseenter", mouseEnter, false);

    const getTouchPoint = (evt) => {
      if (!evt.currentTarget) {
        return [0, 0];
      }
      const rect = evt.currentTarget.getBoundingClientRect();
      const touch = evt.targetTouches[0];
      return [touch.clientX - rect.left, touch.clientY - rect.top];
    };

    const touchStart = (evt) => {
      if (drawing) {
        return;
      }
      evt.preventDefault();
      startStroke(getTouchPoint(evt));
    };

    const touchMove = (evt) => {
      if (!drawing) {
        return;
      }
      continueStroke(getTouchPoint(evt));
    };

    const touchEnd = (evt) => {
      drawing = false;
    };

    canvas.addEventListener("touchstart", touchStart, false);
    canvas.addEventListener("touchend", touchEnd, false);
    canvas.addEventListener("touchcancel", touchEnd, false);
    canvas.addEventListener("touchmove", touchMove, false);
  };

  let fullCanvas = (
    <div style={{ textAlign: "center" }}>
      <img id="init_img" src={inImgSrc} alt="input"></img>
      <canvas id="canvas" height="500" width="320" onMouseEnter={draw}></canvas>
      <button id="resetCanvas" onClick={resetCanvas}>
        Reset the mask
      </button>
    </div>
  );

  const imagesModels = [
    {
      id: 1,
      url: lisa,
      name: "Lisa",
      age: "24 yo",
      country: "France",
      activity: "Student in Economy",
    },
    {
      id: 2,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 3,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 4,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 5,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 6,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 7,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 8,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 9,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 10,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 11,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
    {
      id: 12,
      url: locked,
      name: "LOCKED",
      activity: "Pro Plan needed",
    },
  ];

  const imagesLisa = [
    // {
    //   id: 1,
    //   url: lisa_selfie,
    //   name: "Before university",
    // },
    {
      id: 2,
      url: lisa_school,
      name: "A normal university day",
    },
    // {
    //   id: 3,
    //   url: lisa_defile,
    //   name: "I'm a model on weekends",
    // },
    {
      id: 4,
      url: lisa_mirror,
      name: "I've just woke up",
    },
    {
      id: 5,
      url: lisa_sea,
      name: "Sea is life",
    },
    {
      id: 6,
      url: lisa_gold,
      name: "I'm crazy for this outfit, not you ?",
    },
    {
      id: 7,
      url: lisa_bikini,
      name: "😘😘😘",
    },
    {
      id: 8,
      url: lisa_beach2,
      name: "Holidays at the beach",
    },
    {
      id: 9,
      url: lisa_beach,
      name: "Trying my new swimsuit",
    },
    {
      id: 10,
      url: lisa,
      name: "LOCKED",
      activity: "More pics of me ? Suscribe to Pro Plan",
    },
  ];
  return (
    <section className="generate" id="generate">
      <div className="container">
        <h2 className="title">Generate your DeepNude</h2>
        <br />
        <h1 className="subtitle" id="step1">
          <br />
          <br /> Step 1:
          <br />
          <br /> Upload your image: <br />
          <br />
        </h1>
        <input
          className="upload"
          type="file"
          name="image"
          id="fileInput"
          accept="image/*"
          onChange={viewFile}
        />
        {/* <h1 className="subtitle">
          <br /> or <br />
          <br />
          Choose one of our models:
        </h1> */}
        {/* Image slider chould have an image dictionary created here on the image page with all images 
Then when click on model, it select name and get dictionary of pics of the model name 
finally display the photo on step 2 */}
        {/* <SmallSlider
          imgList={imagesModels}
          onChoose={(img) => setModelName(img.name)}
          href="#models"
        ></SmallSlider>
        <br id="models" /> */}
        {/* {modelName !== "" && (
          <>
            <br />
            <br />
            <h1 className="subtitle">
              <br />
              Choose one of {modelName}'s favourite pics
            </h1>
            <SmallSlider
              imgList={imagesLisa}
              size="auto"
              onChoose={(img) => {
                setIsLoaded(true);
                // Get the image element
                const imgForUrl = document.createElement("img");

                imgForUrl.addEventListener("load", (e) => {
                  // Create a canvas element
                  const canvasForUrl = document.createElement("canvas");

                  // Set the canvas dimensions to the image dimensions
                  canvasForUrl.width = imgForUrl.width;
                  canvasForUrl.height = imgForUrl.height;
                  // Get the canvas context
                  const ctx = canvasForUrl.getContext("2d");

                  // Draw the image on the canvas
                  ctx.drawImage(imgForUrl, 0, 0);
                  // Get the Base64-encoded data
                  const dataURL = canvasForUrl.toDataURL();
                  onLoad(dataURL, "model");
                });

                imgForUrl.src = img.url;

                // ctx.drawImage(
                //   imgForUrl,
                //   imgForUrl.width / 2,
                //   0,
                //   imgForUrl.width - imgForUrl.width / 2,
                //   imgForUrl.height,
                //   canvasForUrl.width / 2,
                //   0,
                //   canvasForUrl.width,
                //   canvasForUrl.height
                // );
              }}
              href="#mask"
            ></SmallSlider>
          </>
        )} */}
        <div className="mask" id="mask" ref={maskRef}>
          {isLoaded && (
            <h1 className="subtitle">
              <br />
              <br /> Step 2: Draw on the image all clothes you want to remove{" "}
              <br />
              <br />
            </h1>
          )}

          {isLoaded && fullCanvas}
          {isLoaded && (
            <h1 className="subtitle">
              <br />
              <br />
              <br />
              <br /> Step 3: Set your preferences <br />
            </h1>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImagePage;
