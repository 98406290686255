import React from "react";
import Footer from "../components/Footer";

const NoPage = () => {
  return (
    <>
      <div>Error 404: Page Not Found </div>
      <Footer></Footer>
    </>
  );
};

export default NoPage;
