import React, { useState } from "react";

const ParametersPage = ({
  gender,
  isNude,
  isPhoto,
  power,
  onClickGender,
  onClickIsNude,
  onClickIsPhoto,
  onClickPower,
  onClickParams,
}) => {
  let [more, setMore] = useState(false);
  // const togglePremiumParams = () => {
  //   var premiumContent = document.getElementById("premiumContent");
  //   if (premiumContent.style.display === "none") {
  //     premiumContent.style.display = "block";
  //   } else {
  //     premiumContent.style.display = "none";
  //   }
  // };

  return (
    <>
      <div className="wrapper">
        <input
          type="radio"
          name="gender"
          id="womanRadio"
          value="woman"
          checked={gender === "woman"}
          onChange={(e) => onClickGender(e)}
        />
        <input
          type="radio"
          name="gender"
          id="manRadio"
          value="man"
          checked={gender === "man"}
          onChange={(e) => onClickGender(e)}
        />
        <label htmlFor="womanRadio" className="option womanRadio">
          <div className="dot"></div>
          <span>Undress Female sex</span>
        </label>
        <label htmlFor="manRadio" className="option manRadio">
          <div className="dot"></div>
          <span>Undress Male sex</span>
        </label>
      </div>
      <div className="wrapper">
        <input
          type="radio"
          name="isNude"
          id="underwearRadio"
          value="underwear"
          checked={isNude === "underwear"}
          onChange={(e) => onClickIsNude(e)}
        />
        <input
          type="radio"
          name="isNude"
          id="nudeRadio"
          value="nude"
          checked={isNude === "nude"}
          onChange={(e) => onClickIsNude(e)}
        />
        <label htmlFor="underwearRadio" className="option underwearRadio">
          <div className="dot"></div>
          <span>Underwear</span>
        </label>
        <label htmlFor="nudeRadio" className="option nudeRadio">
          <div className="dot"></div>
          <span>Nude</span>
        </label>
      </div>
      <div className="wrapper">
        <input
          type="radio"
          name="isPhoto"
          id="photoRadio"
          value="photo"
          checked={isPhoto === "photo"}
          onChange={(e) => onClickIsPhoto(e)}
        />
        <input
          type="radio"
          name="isPhoto"
          id="mangaRadio"
          value="manga"
          checked={isPhoto === "manga"}
          onChange={(e) => onClickIsPhoto(e)}
        />
        <label htmlFor="photoRadio" className="option photoRadio">
          <div className="dot"></div>
          <span>Photo</span>
        </label>
        <label htmlFor="mangaRadio" className="option mangaRadio">
          <div className="dot"></div>
          <span>Anime</span>
        </label>
      </div>
      <div className="wrapper power">
        <input
          type="radio"
          name="power"
          id="lightRadio"
          value="light"
          checked={power === "light"}
          onChange={(e) => onClickPower(e)}
        />
        <input
          type="radio"
          name="power"
          id="strongRadio"
          value="strong"
          checked={power === "strong"}
          onChange={(e) => onClickPower(e)}
        />
        <label htmlFor="lightRadio" className="option lightRadio">
          <div className="dot"></div>
          <span>Light and Fast (better results in general)</span>
        </label>
        <label htmlFor="strongRadio" className="option strongRadio">
          <div className="dot"></div>
          <span>Strong and Slow (better results on dark clothes)</span>
        </label>
      </div>
      <div
        className="params"
        style={{ textAlign: "center" }}
        onClick={() => {
          // onClickParams();
          setMore(!more);
        }}
      >
        <br />
        <h2>More parameters (Pro only)</h2> <br />
        <br />
        {!more && <box-icon name="chevron-down"></box-icon>}
        {more && <box-icon name="chevron-up"></box-icon>}
        {more && (
          <div onClick={() => onClickParams()} style={{ textAlign: "center" }}>
            <h3>
              Get anything you want ! <br /> Just write it in the prompt{" "}
            </h3>
            <input
              type="text"
              id="name"
              maxlength="70"
              size="30"
              disabled
            ></input>
          </div>
        )}
      </div>
      {/* <div>
        <div className="params">
          <h3 className="paramsTitle">Skin Tone</h3>
          <input type="radio" name="skin" id="light" />
          <input type="radio" name="skin" id="mixed" />
          <input type="radio" name="skin" id="dark" />
          <label htmlFor="light" className="option light">
            <div className="dot"></div>
            <span
              style={{
                border: "15px solid rgb(255, 220, 178)",
                borderRadius: "100%",
              }}
            ></span>
          </label>
          <label htmlFor="mixed" className="option mixed">
            <div className="dot"></div>
            <span
              style={{
                border: "15px solid rgb(195,149,130)",
                borderRadius: "100%",
              }}
            ></span>
          </label>
          <label htmlFor="dark" className="option dark">
            <div className="dot"></div>
            <span
              style={{
                border: "15px solid rgb(120, 92, 80)",
                borderRadius: "100%",
              }}
            ></span>
          </label>
        </div>
        <div className="premiumParams">
          <div className="premiumTitle" onClick={togglePremiumParams}>
            Premium parameters <br />
            <box-icon name="chevron-down"></box-icon>
          </div>
          <div
            className="premiumContent"
            id="premiumContent"
            style={{ display: "none" }}
          >
            <div className="params" id="size">
              <h3 className="paramsTitle">Breast Size / Penis Size</h3>
              <input type="radio" name="size" id="small" />
              <input type="radio" name="size" id="big" />
              <label htmlFor="small" className="option small">
                <div className="dot"></div>
                <span>Small</span>
              </label>
              <label htmlFor="big" className="option big">
                <div className="dot"></div>
                <span>Big</span>
              </label>
            </div>
            <div className="params" id="age">
              <h3 className="paramsTitle">Age</h3>
              <input type="radio" name="age" id="young" />
              <input type="radio" name="age" id="middle-aged" />
              <input type="radio" name="age" id="old" />
              <label htmlFor="young" className="option young">
                <div className="dot"></div>
                <span>Young</span>
              </label>
              <label htmlFor="middle-aged" className="option middle-aged">
                <div className="dot"></div>
                <span>Middle-aged</span>
              </label>
              <label htmlFor="old" className="option old">
                <div className="dot"></div>
                <span>Old</span>
              </label>
            </div>
            <div className="params" id="body">
              <h3 className="paramsTitle">Body Type</h3>
              <input type="radio" name="body" id="slim" />
              <input type="radio" name="body" id="fat" />
              <label htmlFor="slim" className="option slim">
                <div className="dot"></div>
                <span>Slim</span>
              </label>
              <label htmlFor="fat" className="option fat">
                <div className="dot"></div>
                <span>Fat</span>
              </label>
            </div>
            <div className="params" id="hairiness">
              <h3 className="paramsTitle">Hairiness</h3>
              <input type="radio" name="hairiness" id="hairy" />
              <input type="radio" name="hairiness" id="shaved" />
              <label htmlFor="hairy" className="option hairy">
                <div className="dot"></div>
                <span>Hairy</span>
              </label>
              <label htmlFor="shaved" className="option shaved">
                <div className="dot"></div>
                <span>Shaved</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ParametersPage;
