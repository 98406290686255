import React, { Component } from "react";
import PricingComponent from "./pricing-component";
import bgbottom from "./images/bg-bottom.svg";
import bgtop from "./images/bg-top.svg";

import "./pricing.css";

class PricingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnnuallyActive: false,
    };
  }
  togglePricing = () => {
    this.setState({
      isAnnuallyActive: !this.state.isAnnuallyActive,
    });
  };
  render() {
    return (
      <div className="pricing-container">
        <img src={bgtop} alt="" />
        <img src={bgbottom} alt="" />

        <div className="pricing-body">
          <h1>Our Pricing</h1>
          <div className="toggle-row">
            <p>Monthly</p>
            <div className="toggle-container">
              <input
                className="toggle-switch"
                type="checkbox"
                id="switch"
                name="switch"
                onClick={this.togglePricing}
              />
            </div>
            <p>Annually</p>
          </div>
          <label className="pricing-card-container" htmlFor="switch">
            {/* <PricingComponent
              pricingHeader="Basic"
              priceAnnually="4.99"
              priceMonthly="6.99"
              storage="50 generations/month"
              allowedUser="Basic parameters"
              isAnnuallyActive={this.state.isAnnuallyActive}
              oneTime={false}
            /> */}
            <PricingComponent
              pricingHeader="Pro"
              priceAnnually="9.99"
              priceMonthly="14.99"
              storage="400 generations/month"
              allowedUser="Access to all parameters for personalisation"
              // gigabits="Faster generation"
              gigabits="Access to our Collection"
              isAnnuallyActive={this.state.isAnnuallyActive}
              oneTime={false}
            />
            <PricingComponent
              pricingHeader="One Time Payment"
              priceAnnually="4.99"
              priceMonthly="4.99"
              storage="Get +50 generations"
              // gigabits="Faster generation"
              isAnnuallyActive={true}
              oneTime={true}
            />
          </label>
        </div>
      </div>
    );
  }
}

export default PricingContainer;
