import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import CGV from "./pages/CGV";
import Privacy from "./pages/Privacy";
import MentionsLegales from "./pages/MentionsLegales";
import TermsOfService from "./pages/TermsOfService";
import Subscriptions from "./pages/Subscriptions";
import NoPage from "./pages/NoPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route index element={<Home />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="cgv" element={<CGV />} />
        <Route path="mentions-legales" element={<MentionsLegales />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="subscriptions" element={<Subscriptions />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

// DONE:
// Put prompts as scenario (list of prompts + n_iter) (1)
// Post an example of prompt scenario on tik tok (1)
// Env vars (1)
// Connect payment API full + if no credit go to payment (2)
// Add a daily limit when subbed (or monthly limit)
// Test on mobile (1)
// Make drawing work with finger
// Recenter page
// Add a cancel sub button with alert or page
// add a delete account button with alert or page
// Connect men radio (1)
// Make it work for men (3) ( minimum !!) (penis are still bad)
// Change Undress frontend (3) (underwear button, sexy clothes ...)
// buy domain name (1)
// Put undress me online (secured with a password ?) (3)
// Change LOGO and brand (minimal)
// pass from dev to prod env (4)
// Mentions legales (3)
// nude => premium parameter

// TODO TODAY: A)
// A) Minimal
// - Post another clothes gif on TikTok (1)
// - Stripe product + decide time etc ...
// - Tests + Put it full online (3)

// B) Optimal

// C) Exquisite (3.5)
// - Create another git branch to separate undress (1)
// - Upload a video to handler (3)
// - Post an inpainting flipbook on tik tok (1) (30')
// 14) Frame by frame img2img decomposition (3)
// add tracking (eyes, color, object.. feature)
// 15) Example for TikTok of video (to keep for tmr) (3)

// D) Dream (dream)
// 16) Create a welcome page on StableStudio.fr, hosted on Amplify
// 17) Create image, video, sound. In image: Txt2img, img2img (resize, recreate), inpainting. In vid: flipbook, vid transformation, stablevideo (coming soon). In sound: txt2sound (stableaudio), img2sound (coming soon)
// 18) Create a logo and minimal frontEnd with payment, signIn etc ..
// X) Play with ControlNet
// X) Check all extensions
// X) Create a test env with sagemaker
// 19) Create backend
// 20) Create txt2img part
// 21) create inpainting part
// 22) create img2img part
// 23) Put Online !
// 24) Tik tok post about it (free usage up to ... to attract people)
// 24) create flipbook part
// 25) create vid transformation part
// 26) create txt2sound part ?

// TODO: Put online (3)

// TODO: add strength or n_iter slider (premium params)

// TODO: pass from dev to prod env (4) (check if db should be done from backend)

// TODO: mentions legales (2)

// TODO: make it work for men (4)

// TODO: administrative (2)

// TODO: go the image part when no image  or no drawing (instead of #undress)

// TODO: hasRead so that some elements are not appearing to disapear (like subscribe button in header)

// NEXT: add models to undress ?

// class App extends Component {
//   state = {
//     val: '',

//   };
//   getPayload = (form) => {
//     // skin: "white", "mixed-race" or "black"
//     // size: "small" or "big"
//     // age: "young", "middle-aged", "old"
//     // body: "slim" or "fat"
//     // hairy: "hairy" or "shaved"
//     // TODO: add more options (pregnant, muscle, half nude ...)
//     var size = form['size'];
//     if (size) {
//       if (form['gender'] == "woman"){
//         size = size + " boobs,"
//       }
//       else if (form['gender'] == "man") {
//         size = size + " penis, "
//       }
//     }
//   };

//   handleSubmit = async (e) => {

//     // Skin Tone
//     formData["skin"] = "";
//     if(document.getElementById('light').checked) {
//       formData.append('skin', 'white');
//       formData["skin"] = "white";
//     }
//     else if (document.getElementById('mixed').checked) {
//       formData["skin"] = "mixed";
//     }
//     else if (document.getElementById('dark').checked) {
//       formData["skin"] = "black";
//     }
//     // Premium
//     // Size
//     formData["size"] = "";
//     if(document.getElementById('small').checked) {
//       formData["size"] = "small";
//     }
//     else if (document.getElementById('big').checked) {
//       formData["size"] = "big";
//     }
//     // Age
//     formData["age"] = "";
//     if(document.getElementById('young').checked) {
//       formData["age"] = "young";
//     }
//     else if (document.getElementById('middle-aged').checked) {
//       formData["age"] = "middle-aged";
//     }
//     else if (document.getElementById('old').checked) {
//       formData["age"] = "old";
//     }
//     // Body
//     formData["body"] = "";
//     if(document.getElementById('slim').checked) {
//       formData["body"] = "slim";
//     }
//     else if (document.getElementById('fat').checked) {
//       formData["body"] = "fat";
//     }
//     // Hairy
//     formData["hairy"] = "";
//     if(document.getElementById('hairy').checked) {
//       formData["hairy"] = "hairy";
//     }
//     else if (document.getElementById('shaved').checked) {
//       formData["hairy"] = "shaved";
//     }

//   };
