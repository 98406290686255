import React from "react";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <>
      <div>
        <h1>Politique de données (Privacy Policies)</h1>
        <br />
        <p>En vigueur et mise à jour le 15 septembre 2024 – AILOVENUDES.COM</p>
        <br />
        <h2>ARTICLE 1 : PRÉAMBULE</h2>
        <br />
        <p>
          La présente politique de confidentialité a pour but d’informer les
          utilisateurs du site : <br />• Sur la manière dont sont collectées
          leurs données personnelles. Sont considérées comme des données
          personnelles, toute information permettant d’identifier un
          utilisateur. A ce titre, il peut s’agir : de ses noms et prénoms, de
          son âge, de son adresse postale ou email, de sa localisation ou encore
          de son adresse IP (liste non-exhaustive) ;<br /> • Sur les droits dont
          ils disposent concernant ces données ;<br /> • Sur le responsable du
          traitement des données à caractère personnel collectées et traitées ;
          <br /> • Sur les destinataires de ces données personnelles ;<br /> •
          Sur la politique du site en matière de cookies. Cette politique
          complète les Mentions Légales et les Conditions Générales
          d’Utilisation consultables par les utilisateurs à l’adresse suivante :
          https://www.ailovenudes.com/mentions-legales/
        </p>
        <br />
        <h2>
          ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES
          DONNÉES PERSONNELLES
        </h2>
        <br />
        <p>
          Conformément à l’article 5 du Règlement européen 2016/679, les données
          à caractère personnel sont :<br />
          • Traitées de manière licite, loyale et transparente au regard de la
          personne concernée ;<br />
          • Collectées pour des finalités déterminées (cf. Article 3.1 des
          présentes), explicites et légitimes, et ne pas être traitées
          ultérieurement d’une manière incompatible avec ces finalités ;<br />
          • Adéquates, pertinentes et limitées à ce qui est nécessaire au regard
          des finalités pour lesquelles elles sont traitées ;<br />
          • Exactes et, si nécessaire, tenues à jour. Toutes les mesures
          raisonnables doivent être prises pour que les données à caractère
          personnel qui sont inexactes, eu égard aux finalités pour lesquelles
          elles sont traitées, soient effacées ou rectifiées sans tarder ;<br />
          • Conservées sous une forme permettant l’identification des personnes
          concernées pendant une durée n’excédant pas celle nécessaire au regard
          des finalités pour lesquelles elles sont traitées ;<br />
          • Traitées de façon à garantir une sécurité appropriée des données
          collectées, y compris la protection contre le traitement non autorisé
          ou illicite et contre la perte, la destruction ou les dégâts d’origine
          accidentelle, à l’aide de mesures techniques ou organisationnelles
          appropriées.
          <br />
          <br />
          Le traitement n’est licite que si, et dans la mesure où, au moins une
          des conditions suivantes est remplie :<br />
          • La personne concernée a consenti au traitement de ses données à
          caractère personnel pour une ou plusieurs finalités spécifiques ;
          <br />
          • Le traitement est nécessaire à l’exécution d’un contrat auquel la
          personne concernée est partie ou à l’exécution de mesures
          précontractuelles prises à la demande de celle-ci ;<br />
          • Le traitement est nécessaire au respect d’une obligation légale à
          laquelle le responsable du traitement est soumis ;<br />
          • Le traitement est nécessaire à la sauvegarde des intérêts vitaux de
          la personne concernée ou d’une autre personne physique ;<br />
          • Le traitement est nécessaire à l’exécution d’une mission d’intérêt
          public ou relevant de l’exercice de l’autorité publique dont est
          investi le responsable du traitement ;<br />• Le traitement est
          nécessaire aux fins des intérêts légitimes poursuivis par le
          responsable du traitement ou par un tiers, à moins que ne prévalent
          les intérêts ou les libertés et droits fondamentaux de la personne
          concernée qui exigent une protection des données à caractère
          personnel, notamment lorsque la personne concernée est un enfant.
        </p>
        <br />
        <h2>
          ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS
          LE CADRE DE LA NAVIGATION SUR LE SITE
        </h2>
        <br />
        <h3>Article 3.1 : Données collectées </h3>
        <br />
        <p>
          Les données personnelles collectées dans le cadre de notre activité
          sont les suivantes :<br />
          adresse mail
          <br />
          La collecte et le traitement de ces données répond à la (aux)
          finalité(s) suivante(s) :<br />
          Suivi de la qualité des services, publicité ciblée
        </p>
        <br />
        <h3>Article 3.2 : Mode de collecte des données</h3>
        <br />
        <p>
          Les données sont conservées par le responsable du traitement dans des
          conditions raisonnables de sécurité, pour une durée qui permet au site
          de protéger les autres utilisateurs, dans le cadre d’une modération de
          contenu.
          <br />
          <br />
          L'entreprise est susceptible de conserver certaines données à
          caractère personnel au-delà des délais annoncés ci-dessus afin de
          remplir ses obligations légales ou réglementaires.
        </p>
        <br />
        <h3>Article 3.3 : Hébergement des données</h3>
        <br />
        <p>Le site AILOVENUDES.COM est hébergé par : (voir mentions légales)</p>
        <br />
        <h3>Article 3.4 : Politique en matière de “cookies”</h3>
        <br />
        <p>
          Lors de chacune de vos visites sur notre site, nous recueillons des
          informations relatives à votre connexion et à votre navigation.
          Différentes technologies peuvent être mises en œuvre pour recueillir
          ces données (Google Analytics). Nous les désignons de façon générique
          « cookie ».
          <br />
          <br />
          Un cookie est un fichier texte déposé lors de la visite d’un site et
          stocké dans un espace spécifique du disque dur de votre ordinateur ou
          de votre téléphone. Les cookies sont gérés par votre navigateur
          Internet et seul l’émetteur d’un cookie peut décider de la lecture ou
          de la modification des informations qui y sont contenues.
          <br />
          <br />
          Un cookie a une durée de validité limitée. Son dépôt et son stockage
          sur votre terminal se font dans le respect de la législation
          applicable et sous réserve des choix que vous avez exprimés et que
          vous pouvez modifier à tout moment.
          <br />
          <br />
          Un cookie ne vous identifie pas personnellement. Il permet à son
          émetteur de reconnaître votre terminal et de collecter un certain
          nombre d’informations relatives à la navigation effectuée depuis ce
          terminal.
          <br />
          <br />
          Les cookies ont plusieurs fonctions, telles que vous permettre de
          naviguer efficacement sur un site ou une application mobile, se
          souvenir de vos choix, des biens et services que vous souhaitez
          acheter, vous proposer des contenus publicitaires pertinents en
          fonction de vos centres d’intérêts exprimés lors de votre navigation.
          Vous trouverez de plus amples informations sur les cookies sur les
          sites suivants :<br />
          www.cnil.fr
          <br />
          www.youronlinechoices.eu
          <br />
          www.aboutcookies.org
          <br />
          www.allaboutcookies.org
          <br />
          <br />
          Pour la gestion des cookies et de vos choix, la configuration de
          chaque navigateur est différente. Elle est décrite dans le menu d’aide
          de votre navigateur, qui vous permettra de savoir de quelle manière
          modifier vos souhaits en matière de cookies.
          <br />
          • Microsoft Internet Explorer
          http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
          <br />
          • Microsoft Edge
          https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy
          <br />
          • Google Chrome
          http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
          <br />
          • Mozilla Firefox
          https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences
          <br />
          • Opera http://help.opera.com/Windows/10.20/fr/cookies.html
          <br />
          <br />
          Pour plus d'informations sur Google Analytics,
          https://policies.google.com/privacy/google-partners. <br /> Pour
          désactiver Google Analytics, vous pouvez aussi utiliser cet add-on:
          https://tools.google.com/dlpage/gaoptout
        </p>
        <br />
        <h2>
          ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA
          PROTECTION DES DONNÉES
        </h2>
        <br />
        <p>
          Les données à caractère personnelles sont collectées par Eclipse
          Creations,
          <br /> N° SIRET : 92965103200013
          <br />
          Adresse : 6 Allée Fajou
          <br />
          97118 Saint-François
          <br />
          <br />
          Le responsable du traitement des données à caractère personnel est le
          dirigeant de Eclipse Creations.
          <br />
          Contact par email: support@ailovenudes.com
          <br />
          <br />
          Si vous estimez, après nous avoir contactés, que vos droits
          “Informatique et Libertés”, ne sont pas respectés, vous pouvez
          adresser une information à la CNIL.
        </p>
        <br />
        <h2>
          ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE
          TRAITEMENT DES DONNÉES
        </h2>
        <br />
        <p>
          Tout utilisateur concerné par le traitement de ses données
          personnelles peut se prévaloir des droits suivants, en application du
          règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi
          78-17 du 6 janvier 1978) :<br /> • Droit d’accès, de rectification et
          droit à l’effacement des données (posés respectivement aux articles
          15, 16 et 17 du RGPD) ;<br /> • Droit à la portabilité des données
          (article 20 du RGPD) ;<br /> • Droit à la limitation (article 18 du
          RGPD) et à l’opposition du traitement des données (article 21 du RGPD)
          ;<br /> • Droit de ne pas faire l’objet d’une décision fondée
          exclusivement sur un procédé automatisé ;<br />
          • Droit de déterminer le sort des données après la mort ; • Droit de
          saisir l’autorité de contrôle compétente (article 77 du RGPD). <br />
          <br />
          Pour exercer vos droits, veuillez adresser votre email à
          support@ailovenudes.com
          <br />
          <br /> Afin que le responsable du traitement des données puisse faire
          droit à sa demande, l’utilisateur peut être tenu de lui communiquer
          certaines informations telles que : ses noms et prénoms, son adresse
          e-mail ainsi que son numéro de compte, d’espace personnel ou d’abonné.{" "}
          <br />
          <br />
          Consultez le site cnil.fr pour plus d’informations sur vos droits.
        </p>
        <br />
        <h2>
          ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE
          CONFIDENTIALITÉ
        </h2>
        <br />
        <p>
          L’éditeur du site AILOVENUDES.COM.COM se réserve le droit de pouvoir
          modifier la présente Politique à tout moment afin d’assurer aux
          utilisateurs du site sa conformité avec le droit en vigueur.
          <br />
          <br />
          Les éventuelles modifications ne sauraient avoir d’incidence sur les
          achats antérieurement effectués sur le site, lesquels restent soumis à
          la Politique en vigueur au moment de l’achat et telle qu’acceptée par
          l’utilisateur lors de la validation de l’achat.
          <br />
          <br />
          L’utilisateur est invité à prendre connaissance de cette Politique à
          chaque fois qu’il utilise nos services, sans qu’il soit nécessaire de
          l’en prévenir formellement.
          <br />
          <br />
          La présente politique, éditée le 15 septembre 2024, a été mise à jour
          le 17 septembre 2024.
        </p>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Privacy;
