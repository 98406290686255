import React from "react";
import Footer from "../components/Footer";

const CGV = () => {
  return (
    <>
      <div>
        <h1>Conditions Générales de Vente</h1>
        <br />

        <p>
          Les présentes Conditions Générales de Vente (ci-après les « CGV »)
          s’appliquent à tous les abonnements et achats (ci-après les « Produits
          ») réalisés sur le site ailovenudes.com (ci-après le « Site »). En
          accédant au Site et en effectuant un achat, vous acceptez sans réserve
          les présentes CGV.
          <br />
          <br />
          <b>Objet :</b> Les présentes CGV ont pour objet de définir les droits
          et obligations des parties dans le cadre de la vente en ligne de
          Produits proposés par ailovenudes.com.
          <br />
          <br />
          <b>Produits :</b> Les Produits disponibles à la vente sont des
          abonnements à nos services. Les caractéristiques essentielles des
          Produits sont décrites sur le Site. Les photographies et les
          descriptions des Produits sont fournies à titre indicatif et n’ont pas
          de valeur contractuelle.
          <br />
          <br />
          <b>Commande :</b> La commande est effectuée en ligne sur le Site.
          Toute commande passée sur le Site constitue un contrat conclu à
          distance entre l’Acheteur et le Vendeur. Les Services proposés par le
          Vendeur sont délivrés au Client en contrepartie d'un prix.
          <br />
          Les paiements sont réalisés directement sur le Site par le biais d’un
          service de paiement en ligne opéré par un tiers, au moyen d’une carte
          de paiement, ou autre moyen de paiement disponible sur le service de
          paiement lequel sera utilisée par le Vendeur pendant toute la durée de
          l’abonnement, sauf si la validité de son moyen de paiement arrive à
          échéance. Dans ce dernier cas, le Client sera tenu de fournir, sans
          délai, un nouveau moyen de paiement au Vendeur.
          <br /> La première mensualité est intégralement payée lors de la
          souscription de l’abonnement. Les mensualités suivantes sont prélevées
          automatiquement tous les mois. En cas de refus de paiement, des
          nouvelles tentatives de paiement sont automatiquement effectuées par
          le Vendeur. <br />
          <br />
          <b>Prix :</b> Les prix des Produits sont indiqués en euros, toutes
          taxes comprises (TTC). Les frais de livraison sont inclus dans le prix
          indiqué. Le Vendeur se réserve le droit de modifier les prix à tout
          moment, mais les Produits seront facturés sur la base des tarifs en
          vigueur au moment de la validation de la commande. <br />
          <br />
          <b>Paiement :</b> Le paiement des Produits s’effectue en ligne par
          carte bancaire ou tout autre moyen de paiement accepté sur le service
          de paiement, ce dernier étant opéré par un tiers. Les transactions
          sont sécurisées grâce à des protocoles de cryptage.
          <br />
          <br />
          <b>Droit de rétractation :</b> Le Client dispose, conformément à la
          loi d'un délai de rétractation de 15 jours à compter de la conclusion
          du contrat pour exercer son droit de rétractation auprès du Vendeur et
          annuler sa commande, à fin de remboursement, sauf si l'exécution des
          prestations a commencé avant la fin du délai de rétractation,
          c’est-à-dire si le Client a utilisé au moins une (1) génération sur
          ailovenudes.com avec l’abonnement concerné, avec l'accord exprès du
          Client et reconnaissance par celui-ci de la perte de son droit à
          rétractation.
          <br /> En cas d'exercice du droit de rétractation dans le délai
          susvisé, seul le prix des Services commandés est remboursé.
          <br /> Le remboursement des sommes effectivement réglées par le Client
          sera effectué dans un délai maximum de 14 jours ouvrés à compter de la
          réception, par le Vendeur, de la notification de la rétractation du
          Client. <br />
          Les échanges pour un produit similaire dans un autre plan ne seront
          pas assortis de frais supplémentaires. Si le nouveau produit est
          différent et qu'il y a une différence de prix, vous pourriez devoir
          payer la différence ou recevoir un remboursement partiel.
          <br /> Les échanges peuvent être effectués jusqu'à 15 jours ouvrables
          après la réception. <br />
          <br />
          <b>Responsabilité :</b> Le Vendeur n’est pas responsable des dommages
          indirects qui pourraient survenir du fait de l’achat des Produits. La
          responsabilité du Vendeur ne saurait être engagée en cas de
          non-respect de la législation du pays où les Produits sont livrés.
          <br />
          <br />
          <b>Propriété intellectuelle :</b> Tous les éléments du Site et les
          Produits sont protégés par le droit d’auteur, le droit des marques
          et/ou autres droits de propriété intellectuelle. Toute reproduction,
          distribution ou utilisation des éléments du Site ou des Produits, sans
          autorisation préalable écrite, est strictement interdite.
          <br />
          <br />
          <b>Protection des données personnelles :</b> Les informations
          personnelles collectées lors de la commande sont nécessaires pour la
          gestion de la transaction et pourront être utilisées à des fins
          marketing, sauf opposition de la part de l’Acheteur. <br />
          Conformément à la loi Informatique et Libertés, l’Acheteur dispose
          d’un droit d’accès, de rectification et de suppression des données le
          concernant. <br />
          <br />
          <b>Droit applicable et juridiction compétente :</b> Les présentes CGV
          sont soumises à la loi française. En cas de litige, les tribunaux
          français seront seuls compétents.
          <br />
          <br />
          <b>Modifications des CGV :</b> Le Vendeur se réserve le droit de
          modifier les présentes CGV à tout moment. Les nouvelles conditions
          seront applicables aux seules ventes réalisées postérieurement à leur
          mise en ligne sur le Site.
          <br />
          <br /> Pour toute demande ou question concernant les conditions
          générales de vente, veuillez contacter support@ailovenudes.com
        </p>
        <br />
      </div>
      <Footer></Footer>
    </>
  );
};

export default CGV;
