import { loadStripe } from "@stripe/stripe-js";
import { fetchUserAttributes } from "@aws-amplify/auth";
import { post } from "aws-amplify/api";

import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function Subscribe({ isAuth, isSub, onIsSub, doSub }) {
  useEffect(() => {
    // checkSub();
  }, [isSub, isAuth]);

  //   const subscribePage = async (e) => {
  //     // TODO: delete
  //     const userAttributes = await fetchUserAttributes();
  //     const stripe = await loadStripe(
  //       "pk_test_51PXmy9B6xInYzrcG6L3NCxxa3p9KwTfa4dnDaPar3DrNXwWXodS3PxLI9UhBu2IifVRD9ZW4nMyogke9mLebpUdp00oprSqvaZ" // process.env.REACT_APP_ENV_STRIPE_TEST_API_KEY // TODO Env vars
  //     );
  //     const { error } = await stripe.redirectToCheckout({
  //       lineItems: [
  //         {
  //           price: "price_1PYpObB6xInYzrcGOXSldmcV", //process.env.REACT_APP_ENV_STRIPE_PRICE_KEY, // TODO Env vars
  //           quantity: 1,
  //         },
  //       ],
  //       mode: "subscription",
  //       successUrl: "http://localhost:3000/", // TODO: put ailovenudes.com ?
  //       cancelUrl: "http://localhost:3000/cancelSubscription",
  //       customerEmail: userAttributes.email,
  //     });
  //   };

  //   const checkSub = async () => {
  //     if (!isAuth) {
  //       const finalDict = { value: false };
  //       if (isSub.value !== finalDict.value) onIsSub(finalDict);
  //       return;
  //     }
  //     try {
  //       const userAttributes = await fetchUserAttributes();
  //       const restOperation = post({
  //         apiName: "undressStripeApi", //process.env.REACT_APP_ENV_STRIPE_API_NAME, // TODO Env vars
  //         path: "/checksub",
  //         options: {
  //           body: {
  //             email: userAttributes.email,
  //           },
  //         },
  //       });
  //       const { body } = await restOperation.response;
  //       const response = await body.json();
  //       const finalDict = {
  //         value: response["isSub"],
  //         product: response["product"],
  //       };
  //       if (
  //         !Object.keys(isSub).includes("product") ||
  //         isSub.value !== finalDict.value ||
  //         isSub.product !== finalDict.product
  //       )
  //         onIsSub(finalDict);
  //     } catch (e) {
  //       console.error("POST call failed: ", e);
  //       alert(
  //         "Something wrong happened: " +
  //           e +
  //           "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
  //       );
  //     }
  //   };

  return (
    <>
      {/* {isAuth && isSub["value"] ? (
        <a href={"https://billing.stripe.com/p/login/test_cN2188564dn4fza144"}>
          Upgrade
        </a>
      ) : ( */}
      {doSub && <Navigate to="/subscriptions" />}
      <a href={"/subscriptions"}>Subscribe</a>
      {/* )} */}
    </>
  );
}
