import React from "react";
import Footer from "../components/Footer";

const MentionsLegales = () => {
  return (
    <>
      <div>
        <h1>Mentions Légales</h1>
        <br />
        <p>
          Le site Internet accessible à l’URL «https://www.ailovenudes.com » est
          la propriété de Eclipse Creations. Conformément à l’article 6 de la
          loi française du 21/06/2004 pour la confiance dans l’économie
          numérique, il est précisé ci-dessous l’identité des différents
          intervenants dans la réalisation et le suivi du site internet : «
          AILOVENUDES.COM » (ci-après le « Site »).
          <br />
          La connexion et la navigation sur le Site par les utilisateurs et
          visiteurs (ci-après l’ « Utilisateur ») du Site implique acceptation
          intégrale et sans réserve des présentes mentions légales.
          <br />
          Ces dernières sont accessibles sur le Site à la rubrique « Mentions
          légales ».
        </p>
        <br />
        <h2>Article 1 – L’éditeur</h2>
        <br />
        <p>
          Le site web www.ailovenudes.com est édité par :<br />
          Eclipse Creations
          <br />
          N° SIRET : 92965103200013
          <br />
          Adresse : 6 Allée Fajou
          <br />
          97118 Saint-François
          <br />
          Contact : support@ailovenudes.com
          <br />
          (ci-après l’ « Editeur »)
        </p>
        <br />
        <h2>Article 2 – L’hébergeur</h2>
        <br />
        <p>
          Hébergeur : Amazon Web Services
          <br />
          Dénomination Sociale : Amazon CS Ireland Ltd
          <br />
          Adresse : Unit 27 – 6400 Cork Airport Business Park – Kinsale Road –
          Ireland
          <br />
          Amazon CS Ireland Ltd est une société du groupe Amazon Web
          Services(AWS), dont le siège social se situe à Inc. P.O/ Box 81226 –
          Seattle, WA 98108-1226, tél : (206) 266-4064 / fax: (206) 266-7010
        </p>
        <br />
        <h2>Article 3 – Accès au site</h2>
        <br />
        <p>
          Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
          majeure, interruption programmée ou non et pouvant découlant d’une
          nécessité de maintenance. En cas de modification, interruption ou
          suspension du Site, l’Editeur ne saurait être tenu responsable
        </p>
        <br />
        <h2>Article 4 – Collecte des données</h2>
        <br />
        <p>
          Le Site assure à l’Utilisateur une collecte et un traitement
          d’informations personnelles dans le respect de la vie privée
          conformément à la loi n°78-17 du 6 janvier 1978 relative à
          l’informatique, aux fichiers et aux libertés.
          <br />
          En vertu de la loi Informatique et Libertés, en date du 6 janvier
          1978, l’Utilisateur dispose d’un droit d’accès, de rectification, de
          suppression et d’opposition de ses données personnelles. L’Utilisateur
          exerce ce droit par mail à l’adresse email suivante :
          support@ailovenudes.com <br />
          <br />
          Toute utilisation, reproduction, diffusion, commercialisation,
          modification de toute ou partie du Site, sans autorisation de
          l’Editeur est prohibée et pourra entraînée des actions et poursuites
          judiciaires telles que notamment prévues par le Code de la propriété
          intellectuelle et le Code civil.
          <br />
          <br />
          Les transactions financières relatives au paiement des achats et des
          frais via le Site, sont confiées à un prestataire de services de
          paiement qui en assure le bon déroulement et la sécurité.
          <br />
          Pour les besoins des services, ce prestataire de services de paiement
          peut être amené à être destinataire de vos données à caractère
          personnel relatives à vos numéros de cartes bancaires, qu’il recueille
          et conserve en notre nom et pour notre compte. <br />
          Nous n’avons pas accès à ces données.
          <br />
          Pour vous permettre de réaliser régulièrement des achats ou de régler
          les frais afférents sur le Site, vos données relatives à vos cartes
          bancaires sont conservées pendant le temps de votre abonnement et à
          tout le moins, jusqu’au moment où vous réalisez votre dernière
          transaction.
          <br />
          Les données relatives au cryptogramme visuel ou CVV2, inscrit sur
          votre carte bancaire, ne sont pas stockées.
          <br />
          Si vous refusez que vos données à caractère personnel relatives à vos
          numéros de cartes bancaires soient conservées dans les conditions
          précisées ci-dessus, nous ne conserverons pas ces données au-delà du
          temps nécessaire pour permettre la réalisation de la transaction.
          <br />
          En tout état de cause, les données relatives à celles-ci pourront être
          conservées, pour une finalité de preuve en cas d’éventuelle
          contestation de la transaction, en archives intermédiaires, pour la
          durée prévue par l’article L 133-24 du Code monétaire et financier, en
          l’occurrence 13 mois suivant la date de débit. Ce délai peut être
          étendu à 15 mois afin de prendre en compte la possibilité
          d’utilisation des cartes de paiement à débit différé.
          <br />
          Pour plus d’informations, se reporter aux CGV du Site accessible à la
          rubrique "CGV"
          <br />
          <br />
          Liens hypertextes :<br />
          Les liens mis en œuvre en direction d’autres sites ne sauraient
          engager la responsabilité de AILOVENUDES.COM, celle-ci n’exerçant
          aucun contrôle sur le contenu de ces sites.
          <br />
          En conséquence, l’entreprise Eclipse Creations ne peut supporter
          aucune responsabilité quant au contenu, publicités, produits, services
          ou tout autre outil disponible sur ou à partir de ces sites ou sources
          externes qu’elle n’a ni vérifié ni approuvé, ni être tenue pour
          responsable de tous dommages consécutifs ou en relation avec
          l’utilisation de ces liens.
        </p>
        <br />
        <h2>Article 5 - Cookies</h2>
        <br />
        <p>
          L'utilisateur est informé que lors de ses visites sur le Site un
          cookie peut s'installer automatiquement sur son logiciel de
          navigation.
          <br />
          Le cookie est un bloc de données qui ne permet pas d'identifier les
          utilisateurs mais sert à enregistrer des informations relatives à la
          navigation de celui-ci sur le Site.
          <br />
          Le paramétrage du logiciel de navigation permet d'informer de la
          présence de cookie et éventuellement, de la refuser de la manière
          décrite à l'adresse suivante : www.cnil.fr.
          <br />
          L'utilisateur dispose de l'ensemble des droits susvisés s'agissant des
          données à caractère personnel communiquées par le biais des cookies
          dans les conditions indiquées ci-dessus.
          <br />
          Les utilisateurs du Site sont tenus de respecter les dispositions de
          la loi n°78-17 du 6 janvier 1978 modifiée, relative à l'informatique,
          aux fichiers et aux libertés, dont la violation est passible de
          sanctions pénales.
          <br />
          Ils doivent notamment s'abstenir, s'agissant des informations à
          caractère personnel auxquelles ils accèdent ou pourraient accéder, de
          toute collecte, de toute utilisation détournée d'une manière générale,
          de tout acte susceptible de porter atteinte à la vie privée ou à la
          réputation des personnes.
        </p>
        <br />
        <h2>Article 6 - Propriété intellectuelle</h2>
        <br />
        <p>
          La structure générale ainsi que les logiciels, textes, images animées
          ou non, son savoir-faire et tous les autres éléments composant le site
          et l’application mobile sont la propriété exclusive de l’Éditeur.
          <br />
          Toute représentation totale ou partielle de ce site ou de cette
          application mobile par quelle que personne que ce soit, sans
          l'autorisation expresse de l’Éditeur est interdite et constituerait
          une contrefaçon sanctionnée par les articles L. 335-2 et suivants du
          Code de la propriété intellectuelle.
          <br />
          Il en est de même des bases de données figurant, le cas échéant sur le
          Site sont protégées par les dispositions de la loi du 1er juillet 1998
          portant transposition dans le Code de la propriété intellectuelle de
          la directive du 11 mars 1996 relative à la protection juridique des
          bases de données, et ce dont l’Éditeur est producteur.
          <br />
          Les marques de l’Éditeur et de ses partenaires, ainsi que les logos
          figurant sur le Site mobile sont, le cas échéant, des marques
          déposées. Toute reproduction totale ou partielle de ces marques ou de
          ces logos effectués à partir des éléments du Site sans l'autorisation
          expresse de l’Éditeur est donc prohibée au sens du Code de la
          propriété intellectuelle.
          <br />
          Les visiteurs du Site ne peuvent mettre en place un hyperlien en
          direction de ces outils sans l'autorisation expresse et préalable de
          l’Éditeur.
          <br />
          L’Éditeur ne saurait être responsable de l'accès par les utilisateurs
          via les liens hypertextes mis en place dans le cadre du site internet
          en direction d'autres ressources présentes sur le réseau.
        </p>
        <br />
      </div>
      <Footer></Footer>
    </>
  );
};

export default MentionsLegales;
