import React from "react";

const GenderSelPage = ({ onClick }) => {
  return (
    <section className="services" id="services">
      <div className="container">
        <div className="card">
          <div className="content">
            <h2 className="title">Undress Female sex</h2>
          </div>
          <a href="#generate" className="">
            <img
              id="womanImg"
              src="/img/woman_gif.gif"
              onMouseOver={(e) => (e.currentTarget.src = "/img/woman_gif.gif")}
              onMouseOut={(e) => (e.currentTarget.src = "/img/woman_gif.gif")}
              onClick={() => onClick("woman")}
              alt="woman getting naked"
            />
          </a>
        </div>
        <div className="card">
          <div className="content">
            <h2 className="title">Undress Male sex</h2>
          </div>
          <a href="#generate" className="">
            <img
              id="manImg"
              src="/img/man_gif.gif"
              onMouseOver={(e) => (e.currentTarget.src = "/img/man_gif.gif")}
              onMouseOut={(e) => (e.currentTarget.src = "/img/man_gif.gif")}
              onClick={() => onClick("man")}
              alt="man getting naked"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default GenderSelPage;
