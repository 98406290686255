import React from "react";
import Footer from "../components/Footer";

const TermsOfService = () => {
  return (
    <>
      <div>
        <h1>Terms of Service (Conditions d'Utilisation)</h1>
        <br />
        <h2>Introduction:</h2>
        <br />
        <p>
          Welcome to ailovenudes.com, an adult image generation website that
          uses artificial intelligence (AI) to create images. By accessing and
          using our website or any related services provided by ailovenudes.com,
          you agree to these Terms of Service, our Privacy Policy (Politique de
          Données) and our Selling Policy (Conditions Générales de Vente).
          <br />
          We, ailovenudes.com, reserve the right to review and amend any of
          these Terms of Service at our sole discretion. Upon doing so, we will
          update this page. Any changes to these Terms of Service will take
          effect immediately from the date of publication. These Terms of
          Service were last updated on 17 September 2024.
        </p>
        <br />

        <h2>Eligibility</h2>
        <br />
        <p>
          You must be at least 18 years old or the age of majority in your
          jurisdiction, whichever is greater, to use our website. By using our
          website, you represent and warrant that you meet these age
          requirements under your local legislation requirements. <br />
          In case you are under 18 years old, you are not entitled to use this
          website.
        </p>
        <br />

        <h2>Limitations of use</h2>
        <p>
          By using this website, you warrant on behalf of yourself, your users,
          and other parties you represent that you will not:
          <br />
          - modify, copy, prepare derivative works of, decompile, or reverse
          engineer any materials and software contained on this website;
          <br />
          - remove any copyright or other proprietary notations from any
          materials and software on this website;
          <br />
          - transfer the materials to another person or “mirror” the materials
          on any other server;
          <br />
          - knowingly or negligently use this website or any of its associated
          services in a way that abuses or disrupts our networks or any other
          service ailovenudes.com provides;
          <br />
          - use this website or its associated services to transmit or publish
          any harassing, indecent, obscene, fraudulent, or unlawful material;
          <br />
          - use this website or its associated services in violation of any
          applicable laws or regulations;
          <br />
          - use this website in conjunction with sending unauthorized
          advertising or spam;
          <br />- harvest, collect, or gather user data without the user’s
          consent; <br />- use this website or its associated services in such a
          way that may infringe the privacy, intellectual property rights, or
          other rights of third parties.
        </p>
        <br />

        <h2>Intellectual property</h2>
        <br />
        <p>
          The intellectual property in the materials contained in this website
          are owned by or licensed to AILOVENUDES.COM and are protected by
          applicable copyright and trademark law. We grant our users permission
          to download one copy of the materials for personal, non-commercial
          transitory use.
          <br />
          This constitutes the grant of a license, not a transfer of title. This
          license shall automatically terminate if you violate any of these
          restrictions or the Terms of Service, and may be terminated by
          ailovenudes.com at any time.
        </p>
        <br />

        <h2>User Responsibility</h2>
        <br />
        <p>
          Users are solely responsible for the images they generate using our
          website. You may not use our website to create images of individuals
          without their consent or of individuals who are not adults. You are
          not allowed to upload photos of celebrities. You may not use our
          website to harm others or engage in any illegal or prohibited
          activities.
        </p>
        <br />

        <h2>Prohibited Uses</h2>
        <br />
        <p>
          You agree not to use the website for any unlawful purposes or in any
          way that violates these Terms of Service. This includes, but is not
          limited to, generating images that contain illegal, offensive, or
          explicit content. You confirm that you undertake all obligations in
          part of abiding by applicable law of your country.
        </p>
        <br />

        <h2>Purchasing Content</h2>
        <br />
        <p>
          You may purchase content made available through our website. Purchases
          are processed through our third-party payment service providers. They
          can be made using credit card or other mean of payment available on
          these payment providers payment page. <br />
          By purchasing content, you agree to abide by the terms and conditions
          and privacy policies of our third-party payment service providers.
        </p>
        <br />

        <h2>Account Registration</h2>
        <br />
        <p>
          To use our website, you may be required to provide an email address.
          We will store this information securely, as described in our Privacy
          Policy.
        </p>
        <br />

        <h2>Content ownership and responsibility</h2>
        <br />
        <p>
          We do not store any content generated by users and are not responsible
          for such content. The user who generates the content is solely
          responsible for its ownership and any consequences that may arise from
          its use or distribution. Our company operates in accordance with
          French legislation.
        </p>
        <br />

        <h2>Termination</h2>
        <br />
        <p>
          We reserve the right to terminate your access to our website at any
          time and for any reason, including but not limited to, a violation of
          these Terms of Service.
        </p>
        <br />

        <h2>Explicit content</h2>
        <br />
        <p>
          We have a zero-tolerance policy for explicit content involving
          underage individuals. Any accounts found to use the website with
          photos of underage individuals for explicit purposes will be
          immediately banned and reported to the appropriate authorities in the
          user's country.{" "}
        </p>
        <br />

        <h2>Privacy Policy</h2>
        <br />
        <p>
          By using our website, you agree to our Privacy Policy (Politique de
          données), which inter alia outlines how we protect your personal
          information.
        </p>
        <br />

        <h2>Changes of the Terms of Service</h2>
        <br />
        <p>
          We may update these Terms of Service from time to time without special
          notification. Any updates come into force as of posting the new Terms
          of Service on our website. <br />
          Your continued use of our website constitutes acceptance of any
          changes to the Terms of Service.
          <br />
          <br />
          If you have any questions or concerns about this Terms of Service,
          please contact us at support@ailovenudes.com
        </p>
        <br />
      </div>
      <Footer></Footer>
    </>
  );
};

export default TermsOfService;
