import React, { useRef } from "react";
import "./style.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const SmallSlider = ({ imgList, size = "", onChoose, href }) => {
  const sliderRef = useRef(null);
  const scrollAmount = 100;

  return (
    <div className="small-slider">
      <button
        className="nav-btn"
        onClick={() => {
          const container = sliderRef.current;
          container.scrollLeft -= scrollAmount;
        }}
      >
        <ChevronLeftIcon />
      </button>
      <div className="images-container" ref={sliderRef}>
        {imgList.map((image) => {
          return (
            <div className="po_item" key={image?.id}>
              <img
                className="image"
                alt="sliderImage"
                key={image?.id}
                src={image?.url}
                style={{ width: size }}
              />

              <div className="content">
                <a
                  href={image?.name === "LOCKED" ? "#step1" : href}
                  onClick={() => {
                    if (image?.name === "LOCKED") {
                      alert(image?.activity + " to unlock this model");
                    } else {
                      onChoose(image);
                    }
                  }}
                >
                  <p>
                    {image?.name} <br />
                    {image?.age} <br />
                    {image?.country} <br />
                    {image?.activity} <br />
                  </p>
                  {/* <button>Select</button> */}
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="nav-btn"
        onClick={() => {
          const container = sliderRef.current;
          container.scrollLeft += scrollAmount;
        }}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
};

export default SmallSlider;
