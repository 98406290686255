/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCount = /* GraphQL */ `
  query GetCount($account: String!) {
    getCount(account: $account) {
      id
      account
      quantity
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCounts = /* GraphQL */ `
  query ListCounts(
    $account: String
    $filter: ModelCountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCounts(
      account: $account
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        account
        quantity
        subscription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const countsById = /* GraphQL */ `
  query CountsById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    countsById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        quantity
        subscription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
