/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCount = /* GraphQL */ `
  mutation CreateCount(
    $input: CreateCountInput!
    $condition: ModelCountConditionInput
  ) {
    createCount(input: $input, condition: $condition) {
      id
      account
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCount = /* GraphQL */ `
  mutation UpdateCount(
    $input: UpdateCountInput!
    $condition: ModelCountConditionInput
  ) {
    updateCount(input: $input, condition: $condition) {
      id
      account
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCount = /* GraphQL */ `
  mutation DeleteCount(
    $input: DeleteCountInput!
    $condition: ModelCountConditionInput
  ) {
    deleteCount(input: $input, condition: $condition) {
      id
      account
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
