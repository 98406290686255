import React, { useState } from "react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { post } from "aws-amplify/api";
import Typewriter from "typewriter-effect";

import AuthComponent from "./AuthComponent";

const HostPage = () => {
  let [watch, setWatch] = useState(false);

  const watchCollection = async () => {
    setWatch(true);
    try {
      const userAttributes = await fetchUserAttributes();
      alert("Only available in Pro Plan");
      const restOperation = post({
        apiName: "undressStripeApi",
        path: "/cancelsub",
        options: {
          body: {
            userEmail: userAttributes.email,
            plan: "Watch",
          },
        },
      });
      const { body } = await restOperation.response;
    } catch (e) {
      console.error("POST call failed: ", e);
    }
  };
  return (
    <section className="home" id="home">
      {watch && <AuthComponent></AuthComponent>}
      <div className="container">
        <h1 className="title principal">
          AI ❤️ Nudes
          {/* <Typewriter
            options={{
              strings: ["Are you ready ?", "..."],
              autoStart: true,
              loop: true,
              deleteSpeed: 10,
            }}
          /> */}
        </h1>
        <br />
        <br />
        <h2 className="subtitle" id="subtitle">
          Best Free AI Undress App
        </h2>
        <br />

        <h2 className=""> Generate Deep Nudes for FREE </h2>
        <br />
        <br />
        <br />
        <div className="links">
          <a href="#services" className="btn-fill">
            Generate a DeepNude
          </a>
          {/* <a
            href="#subtitle"
            className="btn-fill"
            onClick={() => {
              watchCollection();
            }}
          >
            Watch our Collection
          </a> */}
        </div>
        <br />
        <br />
        <br />
        <br />
        {/* <h2 className="info">100% safe: We don't save any picture </h2> */}
      </div>
    </section>
  );
};

export default HostPage;
