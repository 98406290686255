import React from "react";
import { LinearProgress } from "@mui/material";

const LoadingComponent = ({ isInQueue }) => {
  const inProgress = (
    <>
      <h2>It's your turn ! Undressing in progress ...</h2>
      <br></br>
      {/* <LinearProgress color="success" /> */}
      <progress value={null} style={{ width: "300px" }} />
      <br></br>
      <br></br>
      <h2>(This takes less than a minute) </h2>
    </>
  );
  const inQueue = (
    <>
      <br></br>
      <h2>You are in the queue ... </h2>
      <br></br>
      {/* <LinearProgress color="info" /> */}
      <progress value={null} style={{ width: "300px" }} />
    </>
  );
  return <>{isInQueue ? inQueue : inProgress}</>;
};

export default LoadingComponent;
