import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <a href="home" className="logo">
          <img src="/img/ailovenudes_logo.png" alt="" />
        </a>
        <div className="end">
          <p>&copy; Copyright reserved by AILOVENUDES.COM 2024</p>
          <a href="#home" className="to-top">
            <box-icon name="chevron-up"></box-icon>
          </a>
        </div>
        <div className="legal">
          {/* TODO: put unsubscribe and delete account here */}
          <a href="/privacy-policy">Privacy Policy (Politique de Données )</a>
          <a href="/mentions-legales">Legal Mentions (Mentions Légales)</a>
          <a href="/terms-of-service">Terms of Service </a>
          <a href="/cgv">Selling Policy (Conditions Générales de Vente)</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
