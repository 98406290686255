import React, { useEffect } from "react";

const Scroll = () => {
  useEffect(() => {
    const handleScroll = () => {
      try {
        document
          .getElementById("header")
          .classList.toggle("sticky", window.scrollY > 0);
      } catch (e) {
        console.log(e);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return <div></div>;
};

export default Scroll;
