import React, { useState, useEffect } from "react";
import "./pricing.css";
import { post } from "aws-amplify/api";
import { fetchUserAttributes } from "@aws-amplify/auth";

const PricingComponent = ({
  pricingHeader,
  priceAnnually,
  priceMonthly,
  storage,
  allowedUser,
  gigabits,
  isAnnuallyActive,
  oneTime,
}) => {
  let [userId, setUserId] = useState("");
  let [hasRead, setHasRead] = useState(false);

  const subscribe = async (plan) => {
    try {
      const userAttributes = await fetchUserAttributes();
      const restOperation = post({
        apiName: "undressStripeApi",
        path: "/cancelsub",
        options: {
          body: {
            userEmail: userAttributes.email,
            plan: plan,
          },
        },
      });
      const { body } = await restOperation.response;
    } catch (e) {
      console.error("POST call failed: ", e);
      alert(
        "Something wrong happened: " +
          e +
          "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
      );
    }
  };

  const retrieveId = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      const restOperation = post({
        apiName: "undressStripeApi", //TODO env var
        path: "/checksub",
        options: {
          body: {
            userEmail: userAttributes.email,
          },
        },
      });
      const { body } = await restOperation.response;
      return body.json();
    } catch (e) {
      console.error("POST call failed: ", e);
      alert(
        "Something wrong happened: " +
          e +
          "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
      );
    }
  };

  useEffect(() => {
    var userAttributes = {};
    const fetchData = async () => {
      // try {
      //   userAttributes = await fetchUserAttributes();
      //   setUserEmail(userAttributes.email);
      //   if (userAttributes.email === "") console.log("Error: Email is empty");
      // } catch (error) {
      //   console.log(error);
      //   console.log("You need to sign in first");
      // }
      try {
        const response = await retrieveId();
        setUserId(response.userId);
        setHasRead(true);
      } catch (error) {
        console.log(error);
        console.log("An error occured getting your email");
      }
    };
    fetchData();
  }, []);

  const annuallyActiveClass = isAnnuallyActive ? "active" : "";
  const planPeriod = isAnnuallyActive ? "Annually" : "Monthly";
  return (
    <>
      {hasRead && (
        <div className="pricing-card">
          <p className="pricing-header">{pricingHeader}</p>
          <div className="price-container">
            <p className={"toggle-monthly " + annuallyActiveClass}>
              <span>$</span>
              {priceMonthly}
            </p>
            <p className={"toggle-annually " + annuallyActiveClass}>
              <span>$</span>
              {priceAnnually}
            </p>
          </div>
          {!oneTime && (
            <div className="month">
              <p>/month</p>
            </div>
          )}
          <ul>
            <li>
              <p>{storage}</p>
            </li>
            <li>
              <p>{allowedUser}</p>
            </li>
            <li>
              <p>{gigabits}</p>
            </li>
          </ul>
          {!oneTime && (
            <button
              className="button"
              onClick={() => {
                alert(
                  " Oops ! We are victim of our success ... Only One Time Payment is available now. We'll email you as soon as we reopen Pro plan."
                );
                subscribe(pricingHeader + "-" + planPeriod);
              }}
            >
              Subscribe
            </button>
          )}
          {/* TODO */}
          {oneTime && (
            <>
              <a
                className="button"
                target="_blank"
                rel="noopener noreferrer"
                href={"https://ailovecreation.com/subscriptions?n=" + userId}
                onClick={() => {
                  subscribe("OneTimeCard");
                }}
              >
                Select
              </a>

              {/* <p>We pass by our site ailovecreation.com for payment</p> */}
              {/* <button
                className="button"
                onClick={() => {
                  alert(" We're sorry, only payment by card is available now.");
                  subscribe("OneTimeCrypto");
                }}
              >
                Pay with Crypto
              </button> */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PricingComponent;
