import React, { useEffect } from "react";
import { signOut, getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";

// TODO: stop listening if signed IN (until click on signOut)

const SignButton = ({ onSignIn, isAuth, onAuth }) => {
  const signIn = () => {
    onSignIn(true);
  };
  const checkAuth = async () => {
    try {
      await getCurrentUser().then((value) => {
        onAuth(true, value.userId);
      });
    } catch (e) {
      onAuth(false, "");
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);
  const listener = (data) => {
    checkAuth().then(() => {
      onSignIn(false);
    });

    // switch (data.payload.event) {
    //   case "signedIn":
    //     console.log("user signed in");
    //     break;
    //   case "signedOut":
    //     console.log("user signed out");
    //     break;
    //   case "signedUp":
    //     console.log("user signed up");
    //     break;
    //   case "signIn_failure":
    //     console.log("user sign in failed");
    //     break;
    //   case "configured":
    //     console.log("the Auth module is configured");
    //     break;
    // default:
    //   console.log("Something went wrong, look at data object", data);
    // }
  };
  Hub.listen("auth", listener);

  return (
    <div>
      {isAuth ? (
        <button
          className="sign"
          onClick={() => {
            signOut();
          }}
        >
          Sign Out
        </button>
      ) : (
        <button className="sign" onClick={signIn}>
          Sign In
        </button>
      )}
    </div>
  );
};

export default SignButton;
